import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ListGroup } from 'react-bootstrap';


function Pesquisa({id, label, placeholder, itens, selecao, setSelecao, filterFn, labelFn , idFn, notFoundContent }: 
	{id?:string, label?: string, placeholder: string, itens: any[], selecao: any, setSelecao:Function, filterFn?: any, labelFn?: any, idFn?: any, notFoundContent?: Function}) {

  function mostrarPesquisa(): boolean | undefined {
    return (notFoundContentSet || filtered.length > 0) && selecao == null;
  }

  const [filtered, setFiltered] = useState<any[]>([]);
  const [pesquisa, setPesquisa] = useState("");

  const pacientePesquisaInput = useRef<any>();
  let notFoundContentSet = false;

  function pesquisar(v: any) {
    setPesquisa(v.toUpperCase());
    setSelecao(null);
  }

  function selecionarItem(p: any): void {
    setFiltered([]);
    setSelecao(p);
    setPesquisa(labelFn(p));
  }

  useEffect(() => {
	  if(selecao == null && pesquisa.length > 3) {
		   setPesquisa("");
	  }
    
    if(selecao != null) {
      selecionarItem(selecao);
    }
    
  },[selecao])

  if(notFoundContent == null){
	notFoundContent = (p:any) => '';
  }
  else {
	notFoundContentSet = true;	
  }

  let filterFunc = (i:any) => i.nome.toLowerCase()
          .indexOf(pesquisa.toLowerCase()) > -1;

  if(filterFn != null)
    filterFunc = (i:any) => filterFn(i, pesquisa)

  if(filterFn != null)
	filterFunc = (i:any) => filterFn(i, pesquisa)
    

  if(labelFn == null)
    labelFn = (i:any) => i.nome;

  if(idFn == null)
    idFn = (i:any) => i.id;

  useEffect(() => {
    if (!pesquisa || "" === pesquisa) {
      setFiltered([]);
      return;
    }
    if(!itens) return;

    const f = itens.filter(filterFunc as any);

    setFiltered(f);
  }, [pesquisa, itens]);

  return <>
    <Stack gap={2} direction="horizontal">
      {label ? 
      <h6 className="text-nowrap">{label}</h6>
      : ""}
      <Form.Control id={id} ref={pacientePesquisaInput} type="input" autoComplete="no"
        placeholder={placeholder} value={pesquisa}
        onChange={e => pesquisar(e.target.value)} className='custom-select'
        list='pesquisa-paciente' />
    </Stack>
    <ListGroup hidden={!mostrarPesquisa()}
      className='position-absolute'
      style={{ marginLeft: 130, marginTop: -1, zIndex: 10000 }}>
      {filtered.length === 0  && pesquisa?.length > 1? 
		notFoundContent(pesquisa) :
		filtered.map(
        (p) => <ListGroup.Item action key={idFn(p)} onClick={() => selecionarItem(p)}>{labelFn(p)}</ListGroup.Item>)}
    </ListGroup>
  </>;
}
export default Pesquisa;
