import React, { useEffect, useState } from "react";
import { useSendGet, useSendPost, useSendPut } from "../../Server";
import { vazio } from "../../Validacao";
import State from "./State";

export const useLoadPrescricao = (setDados: Function, setItensPrescricao: Function, setIdItem: Function, setPosologiaRef: Function, setQuantidadeFocusRef: Function) => {
  const sendGet = useSendGet();
  return (id: any) => {
    if (!id) return;

    sendGet("prescricao/data/" + id,
      (response: any) => {
        let d = response.data;
        setDados({
          ...d,
          paciente: d.pacienteObj
        });
        let itens = [
          ...d.itens.map((i: any, idx: number) => {
            return { id: idx, ...i.medicamentoObj, ...i, tipo: "medicamento" };
          }),
          ...d.itensTexto.map((i: any, idx: number) => {
            return { id: idx + d.itens.length, ...i, composicao: i.titulo, posologia: i.texto, tipo: "texto_livre" };
          })];

        itens.sort((a: any, b: any) => a.sequencial > b.sequencial ? 1 : -1);

        setPosologiaRef(itens.map((i: any) => React.createRef()));
        setQuantidadeFocusRef(itens.map((i: any) => false));
        setItensPrescricao(itens);
        setIdItem(itens.length);

        console.log(d);
      },
      (error: any) => {
        console.log(error);
      });
  }
}

export const usePesquisaMedicamentos = (setFilteredMedicamento: Function, medicamentosList:any[]) => {
  
  return (pesquisaMedicamento:string) => {
    if (!pesquisaMedicamento || "" === pesquisaMedicamento || pesquisaMedicamento.length < 3) {
      setFilteredMedicamento([]);
      return;
    }

    let termos = pesquisaMedicamento.toLowerCase().split(" ");

    let f = medicamentosList.filter((m: any) =>
      termos.reduce((p, v) => p >= 0 ?
        [1, 2, 3, 4].reduce((pv, cv) => pv < 0 ? m[cv].toLowerCase().indexOf(v) : pv, -1) : p, 1) > -1);


    const r = f.map((i: any) => {
      return {
        coluna1: (i[1] + " " + i[2] + " " + i[3]).toLowerCase(),
        coluna2: (i[5] + " " + i[6] + " " + i[7] + " " + i[8]).toLowerCase(),
        coluna3: (i[9] + " " + i[10]).toLowerCase(),
        medicamento: i[0],
        nome: i[1],
        complemento: i[2],
        tipoApresentacao: i[3],
        composicao: i[4],
        quantidadeApresentacao: i[5],
        unidadeApresentacao: i[6],
        quantidadeVolume: i[7],
        unidadeVolume: i[8],
        uso: i[9],
        laboratorio: i[10],
        precoSemImpostos: i[11],
        conteudo: i[12],
        tipoReceituario: i[13]
      };
    });

    setFilteredMedicamento(r);
  };
}

export const useSalvarPrescricao = (setAlerta: Function, dadosf:Function, setDados:Function, setState:Function , itensPrescricaof:Function, idf:Function, pacientesDadosUpdate:Function) => {
  
  var salvar = false;

  const sendPost = useSendPost();
  
  return () => {

    if (salvar) return;
    salvar = true;
    


    const validaSalvarPrescricao = () => {
      const dados = dadosf();
      const itensPrescricao = itensPrescricaof();

      let msg = null;
      if (msg == null && vazio(dados.paciente))
        msg = { type: 'warning', message: 'Infome os dados do paciente' };

      if (msg == null && vazio(itensPrescricao))
        msg = { type: 'warning', message: 'Inclua pelo menos um item na prescricao' };

      if (msg == null && itensPrescricao.some((i: any) => vazio(i.posologia)))
         msg = { type: 'warning', message: 'Todos os itens devem ter a posologia.' };

      if (msg) {
        setAlerta(msg);
        return false;
      }
      return true;
    };

    if (!validaSalvarPrescricao()) {
      salvar = false;
      return;
    }

    const savePresc = () => {
      const dados = dadosf();
      const itensPrescricao = itensPrescricaof();
      const id = idf();

      let itens:any[] = itensPrescricao.map((k: any, i: number) => {
        k.sequencial = (i + 1);
        return k;
      });

      const ppath = id?
        "prescricao/update/" + id : "prescricao/save";

      let data:any = {
        paciente: dados.paciente.paciente,
        data: dados.data,
        itens: itens.filter((i: any) => i.tipo == "medicamento").map((k: any, i: number) => {
          return {
            medicamento: k.medicamento,
            posologia: k.posologia,
            quantidade: k.quantidade,
            sequencial: k.sequencial
          }
        }),
        itensTexto: itens.filter((i: any) => i.tipo == "texto_livre").map((k: any, i: number) => {
          return {
            titulo: k.composicao,
            texto: k.posologia,
            quantidade: k.quantidade,
            quantidadeTexto: k.quantidadeTexto,
            sequencial: k.sequencial,
            unidadeApresentacao: k.unidadeApresentacao,
            tipoTexto: k.tipoTexto
          }
        })};

        sendPost(ppath, data,
          (response: any) => {
            setDados({ ...dados, id: response.data.prescricao });
            setState(State.gerar_prescricao);
            salvar = false
          },
          (error: any) => {
            setAlerta({ type: 'danger', message: error.response.data });
            salvar = false;
          });
    }

    const dados = dadosf();
    const path = dados.paciente?.paciente ?
      "paciente/update/prescricao/" + dados.paciente.paciente :
      "paciente/create/prescricao";

    sendPost(path, dados.paciente,
      (response: any) => {
        console.log(response);
        dados.paciente = response.data;
        pacientesDadosUpdate((item:any) => {
          console.log(item);
          return item.paciente != dados.paciente.paciente ;
        }, dados.paciente);
        savePresc();
      }, (error: any) => {
        setAlerta({ type: 'danger', message: error.response.data });
        salvar = false;
      });
  } 
}


export const useAtualizarPrescricoesRecentes = (setPrescricoesRecentes:Function) => {
  const sendGet = useSendGet();

  var recentes = false;

  useEffect(() => {
    if(recentes) return;
    recentes = true;
    sendGet("prescricao/recentes", (response: any) => {
      setPrescricoesRecentes(response.data);  
      recentes = false;
    })
  },[]);

  return () => {};
}


export const updateTextAreaCustomHeight = () => {
  const textareas = document.querySelectorAll('.textarea-custom');
  textareas.forEach((element: any) => {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
  });
}

export const useIncId = ():[(id: number) => void, () => number] => {
  const [idItem, setIdItem] = useState<number>(0);
  function nextId() {
    let next = idItem + 1;
    setIdItem(next);
    return next;
  }
  return [setIdItem, nextId];
}
