import Form from 'react-bootstrap/Form';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, ListGroup, Nav, Row, Stack, Table } from 'react-bootstrap';
import MenuCelular from './MenuCelular';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import Pesquisa from '../componentes/Pesquisa';
import InputMask from 'react-input-mask';
import { useKeycloak } from 'keycloak-react-web';
import { useNavigate, useParams } from 'react-router-dom';
import { useSendGet, useSendPost, useSendPut } from '../Server';
import { useLocalCache, useLocalCacheUpdate } from '../componentes/LocalCache';
import { useAlerta } from '../componentes/Alerta';
import { verificaCpf, verificaDataNascimento, verificaEmail, verificaNome, verificaTelefone } from '../componentes/Validacao';
import { vazio, vazioProps } from '../Validacao';
import { listaAlergias, listaCidades, listaEstados, listaRaca, listaSexo } from '../componentes/DadosComuns';
import Lista from '../componentes/Lista';


function PacienteEditor() {
  const { id } = useParams();
  const [dados, setDados] = useState<any>({ alergias: [], condicoes: [], condicoesPadrao: [] });

  const [selected, setSelected] = useState("dados");

  const navigate = useNavigate();

  const [listaCidadesFiltro, setListaCidadesFiltro] = useState<any[]>([]);

  const [pacientes, setPacientes] = useState([]);
  const pacientesDados = useLocalCache("pacientes", "paciente/medico", setPacientes);

  const [condicoes, setCondicoes] = useState([]);
  const condicoesDados = useLocalCache("condicoes", "condicao", setCondicoes);

  const [alergenicos, setAlergenicos] = useState([]);
  const alergenicosDados = useLocalCache("alergenicos", "alergenico", setCondicoes);

  useEffect(() => {
    pacientesDados();
    condicoesDados();
  }, []);

  const [MensagemAlerta, setAlerta] = useAlerta();

  const dadosHistorico: any = [
    //{ data: '15/03/2021', observacao: "Dores nas costas, dipirona", documentos: [{ tipo: "Prescrição", codigo: 123 }] }
  ];

  const sendGet = useSendGet();

  useEffect(() => {
    if (!id) return;
    sendGet("paciente/" + id, (response: any) => {
      let data = response.data;
      data.cidadeObj = { id: 1, nome: data.cidade };
      setDados(data);
    });
  }, [id]);

  useEffect(() => {
	let cidades = listaCidades.filter((c) => c.estado === parseInt(dados.estado));
	setListaCidadesFiltro(cidades)	
  }, [dados.estado]);

  const sendPostF = useSendPost();
  const sendPut = useSendPut();

  const cacheUpdate = useLocalCacheUpdate("pacientes");

  function sendPost() {
    let msg = null
    let props = vazioProps(dados, ['nome', 'peso', 'sexo', 'dataNascimento'])
    if (msg == null && props.length > 0)
      msg = { type: 'warning', message: 'Informe dados do paciente: ' + props.join(', ') };

    if (msg != null) {
      setAlerta(msg)
      return;
    }

    if (!vazio(dados.cpf) && !verificaCpf(dados.cpf, id, pacientes, setAlerta)) return;

    let update = id ? sendPut : sendPostF;

    let d = { ...dados, cidade: dados.cidadeObj?.nome };
    update(
      id ? "paciente/" + id : "paciente",
      d,
      (response: any) => {
        console.log('data', response.data);
        cacheUpdate((d: any) => d.paciente !== response.data.paciente, response.data);
        navigate("/pacientes");
      },
      (error: any) => {
        setAlerta({ type: 'danger', message: error.response.data });
      });
  } 

  function updateCondicaoItemCheckbox(ev: any): void {
    console.log(ev.target)
    if (ev.target.checked) {
      setDados({
        ...dados, condicoesPadrao:
          [...dados.condicoesPadrao, { id: ev.target.id }]
      });
    }
    else {
      setDados({
        ...dados, condicoesPadrao:
          dados.condicoesPadrao.filter((i: any) => i.id !== ev.target.id)
      });
    }
  }

  function cancelar(): void {
    navigate("/pacientes");
  }



  return <>
    <MenuPrincipal />
    <Container className="mt-3">
      <Row>
        <Col>
          <h2>Paciente</h2>
        </Col>
      </Row>
      <MensagemAlerta />
      <Form autoComplete="no">
        <Row>
          <Col md={{ span: 4 }}>
            <Card className="d-none d-md-block">
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Text>
                  Acesse abaixo as informações do paciente.
                  <p className='fw-bold'>{dados.nome}</p>
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item onClick={() => setSelected("dados")} active={selected === 'dados'}>Dados gerais</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("contato")} active={selected === 'contato'} >Contato e Endereço</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("outros_dados")} active={selected === 'outros_dados'} >Outros dados</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("condicoes")} active={selected === 'condicoes'} >Condições</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("alergias")} active={selected === 'alergias'}>Alergias</ListGroup.Item>
                <ListGroup.Item onClick={() => setSelected("historico")} active={selected === 'historico'} >Historico</ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col className={(selected === 'dados' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Dados</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Nome *</Form.Label>
              <Form.Control id="nome" name='nome' type="input" placeholder="Digite o nome do seu paciente" autoComplete="no"
                value={dados.nome} onChange={e => setDados({ ...dados, nome: e.target.value.toUpperCase() })} 
                onBlur={e => verificaNome(e.target.value, dados.paciente, pacientes, setAlerta)} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>CPF</Form.Label>
              <InputMask className="form-control" mask="999.999.999-99" value={dados.cpf} onChange={e => setDados({ ...dados, cpf: e.target.value })} autoComplete="no"
                type="input" placeholder="Digite o cpf do seu paciente" aria-describedby="cpfdescription" id="cpf"
                onBlur={e =>  verificaCpf(e.target.value, dados.paciente, pacientes, setAlerta)} />

            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Data Nascimento *</Form.Label>
              <Form.Control id="dataNascimento" type="date" placeholder="Selecione a data de nacimento" autoComplete="no"
                value={dados.dataNascimento} onChange={e => setDados({ ...dados, dataNascimento: e.target.value })}
                onBlur={e => verificaDataNascimento(new Date(e.target.value), setAlerta)} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Sexo *</Form.Label>
              <Form.Select aria-label="Selecione o sexo do seu paciente"
                id="sexo" onChange={e => setDados({ ...dados, sexo: e.target.value })} >
                <option>Selecione</option>
                {listaSexo.map((i) => <option selected={dados.sexo == i.id} value={i.id}>{i.nome}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Peso(kg) * </Form.Label>
              <Form.Control type="input" placeholder="Digite o peso do seu paciente"  autoComplete="no"
                id="peso" value={dados.peso} onChange={e => setDados({ ...dados, peso: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Altura(m)</Form.Label>
              <Form.Control type="input" placeholder="Digite a altura do seu paciente" autoComplete="no"
                id="altura" value={dados.altura} onChange={e => setDados({ ...dados, altura: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Raça</Form.Label>
              <Form.Select aria-label="Selecione a raça do seu paciente"
                id="raca" onChange={e => setDados({ ...dados, raca: e.target.value })} >
                <option>Selecione</option>
                {listaRaca.map((i) => <option selected={dados.raca == i.id} value={i.id}>{i.nome}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className={(selected === 'contato' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Contato e Endereço</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Telefone</Form.Label>
              <Form.Control id="telefone" type="input" placeholder="Digite o telefone do seu paciente" autoComplete="no"
                value={dados.telefone} onChange={e => setDados({ ...dados, telefone: e.target.value })} onBlur={e => verificaTelefone(e.target.value, id, pacientes, setAlerta)} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>E-mail</Form.Label>
              <Form.Control id="email" type="input" placeholder="Digite o email do seu paciente" autoComplete="no"
                value={dados.email} onChange={e => setDados({ ...dados, email: e.target.value })} onBlur={e => verificaEmail(e.target.value, id, pacientes, setAlerta)} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Endereço</Form.Label>
              <Form.Control id="endereco" type="input" placeholder="Digite o endereço do seu paciente" autoComplete="no"
                value={dados.endereco} onChange={e => setDados({ ...dados, endereco: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Estado</Form.Label>
              <Form.Select id="estado" aria-label="Selecione a raça do seu paciente"
                onChange={e => setDados({ ...dados, estado: e.target.value })}>
                <option>Selecione</option>
                {listaEstados.map((e) => <option selected={e.id == dados.estado} value={e.id}>{e.nome}</option>)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Cidade</Form.Label>
              <Pesquisa id="cidade" selecao={dados.cidadeObj} setSelecao={(s: any) => setDados({ ...dados, cidadeObj: s })} itens={listaCidadesFiltro} 
                notFoundContent={(pesquisa: any) => dados.cidadeObj.nome != pesquisa?  setDados({...dados, cidadeObj: {id: null, nome: pesquisa}}) : null} placeholder='Selecione a cidade' />
            </Form.Group>
          </Col>
          <Col className={(selected === 'outros_dados' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Outros dados</h3>
            <Form.Group className="mb-3" >
              <Form.Label>Nome mãe</Form.Label>
              <Form.Control type="input" placeholder="Digite o nome da mãe do seu paciente" autoComplete="no"
                id="nomeMae" value={dados.nomeMae} onChange={e => setDados({ ...dados, nomeMae: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Profissão</Form.Label>
              <Form.Control type="input" placeholder="Digite a profissão do seu paciente" autoComplete="no"
                id="profissao" value={dados.profissao} onChange={e => setDados({ ...dados, profissao: e.target.value })} />
            </Form.Group>
          </Col>
          <Col className={(selected === 'condicoes' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <h3>Condições</h3>
            <Row className="mt-3">
              <Stack direction="horizontal" gap={4}>
                <Form.Check type="checkbox" label="Gravidez" id="gravidez"
                  onChange={(e) => updateCondicaoItemCheckbox(e)} />

                <Form.Check type="checkbox" label="Lactaçao" id="lactacao"
                  onChange={(e) => updateCondicaoItemCheckbox(e)} />
              </Stack>
            </Row>
            <Lista pesquisaItens={condicoes} placeholder='Selecione aqui outra condição' itens={dados.condicoes} setItens={(itens: any[]) => setDados({ ...dados, condicoes: itens })} />
          </Col>
          <Col className={(selected === 'alergias' ? 'd-block' : 'd-md-none') + " mb-5"} md={{ span: 8 }}>
            <Lista pesquisaItens={listaAlergias} label='Alergias' placeholder='Selecione o alergenico' itens={dados.alergias} setItens={(itens: any[]) => setDados({ ...dados, alergias: itens })} />
          </Col>
          <Col className={(selected === 'historico' ? 'd-block' : 'd-md-none') + " mb-4"} md={{ span: 8 }}>
            <h3>Histórico</h3>
            <Row className="mt-3">
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Observação</th>
                      <th>Documentos</th>
                    </tr>
                  </thead>
                  {
                    dadosHistorico.map((item: any) => (
                      <tr className=''>
                        <td>{item.data}</td>
                        <td>{item.observacao}</td>
                        <td>{item.documentos.map((i: any) => <Nav.Link href={"prescricao/" + i.codigo}>{i.tipo}</Nav.Link>)}</td>
                      </tr>
                    ))
                  }
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm={{ span: 4 }}>
            <Stack gap={2} direction="horizontal">
              <Form.Group className="mt-3" >
                <Button type='button' variant='secondary' onClick={() => cancelar()}>Cancelar</Button>
              </Form.Group>
              <Form.Group className="mt-3" >{
                id ? <Button type='button' variant='primary' onClick={() => sendPost()}>Atualizar</Button> :
                  <Button type='button' variant='primary' onClick={() => sendPost()}>Incluir</Button>
              }
              </Form.Group>
            </Stack>
          </Col>
        </Row>
      </Form>
    </Container>
    <MenuCelular />
  </>
}

export default PacienteEditor;


