import { ReactNode, useEffect, useState } from "react";
import { Accordion, Col, Row, Form, Button, Stack } from "react-bootstrap";
import { CaretDownFill, CaretUpFill, ThreeDotsVertical } from "react-bootstrap-icons";
import { useLocalCache } from "../../componentes/LocalCache";

export const usePrescricaoRender = (medicamentosList: Function, mostrarPrecscricoesRecentes: Function,
  prescricoesRecentes: Function, navigate: Function) => {


  const descricaoPrescricaoItens = function(medicamentosList: [], pl: any[]): any[] {
    if (!pl) return pl;

    let result = "";
    let ids = pl.filter(p => p.itens != null).reduce((arr: any[], p: any) => {
      return [...arr, ...p.itens.map((i: any) => '' + i.medicamento).filter((i: any) => !arr.includes(i))];
    }, []);

    let medlist = medicamentosList.filter((i: any) => ids.includes(i[0]));
    pl = pl.map(p => {

      if (!p.itens) {
        p.itens = [];
      }

      let itens = medlist.filter((i: any) => p.itens.map((j: any) => '' + j.medicamento).includes(i[0]));
      if (!p.itensTexto) {
        p.itensTexto = [];
      }

      let l = [...p.itens, ...p.itensTexto];

      l.sort((a: any, b: any) => a.sequencial > b.sequencial ? 1 : -1)
      p.descricaoItens = l.map(i => i.itemTexto ? i.titulo : itens.filter((j: any) => j[0] == '' + i.medicamento)[0][1]).join(",");

      return p;
    })
    pl.sort((a: any, b: any) => a.prescricao < b.prescricao ? 1 : -1);
    return pl;
  }

  function ultimasPrescricoes(): JSX.Element {
    let pr = [];
    if (mostrarPrecscricoesRecentes()) {
      pr = descricaoPrescricaoItens(medicamentosList(), prescricoesRecentes());
    }
    return <>
      {mostrarPrecscricoesRecentes() ?
        <Accordion className='mt-5'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header> Ultimas prescrições </Accordion.Header>
            <Accordion.Body>

              <Row>
                {pr.filter((_, idx, arr) => idx < arr.length - arr.length % 2)
                  .map(r => <Col className="mt-3 h-100" key={r.id} xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 3 }}>
                    <h6 className='text-primary'>{r.pacienteObj.nome}</h6>
                    <p>{r.descricaoItens} <br />Emitida em {new Date(r.data).toLocaleString('pt-BR', { dateStyle: 'short' })}
                      <br />
                      <a onClick={() => navigate("/prescricao/" + r.prescricao)} href="#">Abrir</a>
                    </p>
                  </Col>
                  )}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> : <></>}
    </>
  }

  return [ultimasPrescricoes];
}


export function useItemPrescricaoRender(posologiaRefFn: Function, itemPrescricaoOnChange: Function, iniciarIncluirPrescricao: Function,
  setQuantidadeFocusRef: Function, getQuantidadeFocusRef: Function, aumentarQuantidade: Function, reduzirQuantidade: Function,
  removerItemPrescricao: Function, ajustarQuantidade: Function): [(p: any, index: number) => ReactNode, (p: any, index: number) => ReactNode] {

  const [itemTextoTipoList, setItemTextoTipoList] = useState([]);
  const itemTextoTipoListDados = useLocalCache("item_texto_tipo", "item_texto_tipo", setItemTextoTipoList);
  
  useEffect(() => {
     itemTextoTipoListDados();
   }, []);

  const itemPrescricaoRender = (p: any, index: number) => {

    const posologiaRef = posologiaRefFn();
    const quantidadeFocusRef = getQuantidadeFocusRef();

    return <Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
      <Row>
        <Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
          <Row>
            <Col xs={{ span: 1 }}>
              <p className="m-5 mt-0 bold">{(index +1) +". "}</p>
              <DragHandler />
            </Col>
            <Col xs={{ span: 6 }}>
              <div className="texto-nome-medicacao">
                <span className="bold">{p.nome + " " + p.complemento + ", "}</span> {p.tipoApresentacao} {p.tipoReceituario}, {p.laboratorio}
                {/*<span className="bold">{p.nome + ", "}</span> {p.tipoApresentacao}, {p.laboratorio} */}
              </div>
              <Form.Control
                as="textarea"
                rows={1}
                value={p.composicao}
                className="custom-select texto-composicao border-0 mt-1 textarea-custom"
                readOnly
                plaintext
                style={{ marginLeft: '7px' }}
              />
              <Form.Control
                as="textarea"
                rows={1}
                value={"Embalagem com " + p.conteudo}
                className="custom-select texto-conteudo border-0 mt-1 textarea-custom"
                readOnly
                plaintext
                style={{ marginLeft: '7px' }}
              />
              <Form.Control
                as="textarea"
                rows={2}
                ref={posologiaRef[index]}
                value={p.posologia}
                key={'posologia' + index}
                placeholder="Digite aqui a posologia"
                onChange={(e) => itemPrescricaoOnChange(e, p, 'posologia')}
                onKeyPress={(e) => {
                  if (e.key.toLowerCase() === 'enter') {
                    iniciarIncluirPrescricao();
                  }
                }}
                className="custom-select border-0 mt-1 textarea-custom placeholder-blue"
                list='pesquisa-medicamento'
              />
            </Col>
            <Col xs={{ span: 3 }} onMouseOver={() => setQuantidadeFocusRef(quantidadeFocusRef.map((v: any, i: any) => i == index ? true : v))}
              onMouseOut={() => setQuantidadeFocusRef(quantidadeFocusRef.map((v: any, i: any) => i == index ? false : v))} >
              <div style={{height: 45}}>
                <Button variant="outline-primary" className="mx-5" style={{ width: 150, display: quantidadeFocusRef[index] ? 'block' : 'none' }} onClick={() => aumentarQuantidade(p)}><CaretUpFill /></Button>
              </div>
              <Form.Control
                as="textarea"
                rows={1}
                value={(p.quantidade * parseFloat(p.quantidadeApresentacao)) + " " + p.unidadeApresentacao}
                className="custom-select border-0 textarea-custom"
                plaintext
                readOnly
                style={{ textAlign: 'right' }}
              />
              <div style={{height: 45}}>
                <Button variant="outline-primary" className="mx-5" style={{ width: 150, display: quantidadeFocusRef[index] ? 'block' : 'none' }} onClick={() => reduzirQuantidade(p)}><CaretDownFill /></Button>
              </div>
            </Col>
            <Col xs={{ span: 2 }}>
              <Button variant="outline-primary" className="mutted" onClick={() => removerItemPrescricao(p, index)}>
                Remover
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  }

  const DragHandler = ():any => {
    return <ThreeDotsVertical style={{ cursor: 'pointer' }} className="text-secondary item-prescricao-handle" />
  }   
  const textoPrescricaoRender = (p: any, index: number): ReactNode => {

    const posologiaRef = posologiaRefFn();
    const quantidadeFocusRef = getQuantidadeFocusRef();

    return <Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
      <Row>
        <Col key={p.id} sm={{ span: 12 }} className="border-bottom pb-3 mt-3">
          <Row>
            <Col xs={{ span: 1 }}>
              <DragHandler />
              <h3>{index}</h3>
            </Col>
            <Col xs={{ span: 6 }}>
              <Form.Control
                as="textarea"
                rows={1}
                value={p.composicao}
                className="bold texto-nome-medicacao custom-select border-0 mt-1 textarea-custom"
                placeholder="Digite o titulo"
                plaintext
                onChange={(e) => itemPrescricaoOnChange(e, p, 'composicao')}
                style={{ marginLeft: '7px' }}
              />
              <Form.Control
                type="input"
                as="textarea"
                ref={posologiaRef[index]}
                value={p.posologia}
                key={'posologia' + index}
                placeholder="Digite o conteudo"
                onChange={(e) => itemPrescricaoOnChange(e, p, 'posologia')}
                className="custom-select border-0 mt-1 textarea-custom placeholder-blue"
                list='pesquisa-medicamento'
              />
            </Col>
            <Col xs={{ span: 3 }}  >
              <div onMouseOver={() => setQuantidadeFocusRef(quantidadeFocusRef.map((v: any, i: any) => i == index ? true : v))}
                onMouseOut={() => setQuantidadeFocusRef(quantidadeFocusRef.map((v: any, i: any) => i == index ? false : v))}>
              <datalist id='opcoes-quantidade-item-texto'>
                <option value="(Em branco)" />
                <option value="Uso contínuo" />
              </datalist>
                <Form.Control
                  className="custom-select border-0 textarea-custom"
                  list="opcoes-quantidade-item-texto"
                  placeholder="Digite a quantidade"
                  onChange={(e) => itemPrescricaoOnChange(e, p, 'quantidadeTexto')}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <Row>
                <Col className='col' ></Col>
                <Form.Group className="my-3 mx-0 px-0  col-auto float-end" >
                  <Form.Select title="Selecione o tipo de texto livre" className="border-0 mx-1 mt-1 float-end "
                    id="itemTextoTipo" onChange={e => itemPrescricaoOnChange(e, p, 'tipoTexto')} >
                    {itemTextoTipoList.map((i: any) => <option selected={p.tipoTexto == i.itemTextoTipo} value={i.itemTextoTipo}>{i.nome}</option>)}
                  </Form.Select>
                </Form.Group>
              </Row>
            </Col>
            <Col xs={{ span: 2 }}>
              <Button variant="outline-primary" className="mutted" onClick={() => removerItemPrescricao(p, index)}>
                Remover
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  }

  return [itemPrescricaoRender, textoPrescricaoRender];

}
