import React, { ChangeEvent, useEffect, useState } from "react";
import State from "./State";
import { atualiarPluralUnidadeApresentacao } from "./PrescricaoUnidades";
import { updateTextAreaCustomHeight } from "./PrescricaoFuncoes";

export const useItemPrescricao = (
  nextId: Function, setPesquisaMedicamento: Function, setState: Function,
  posologiaRef: Function, setPosologiaRef: Function, quantidadeFocusRef: Function,
  setQuantidadeFocusRef: Function, pesquisaMedicamento: Function, iniciarIncluirPrescricao: Function): any[] => {

  const [itensPrescricao, setItensPrescricao] = useState<any[]>([]);

  useEffect(updateTextAreaCustomHeight, [itensPrescricao]);

  function incluirPrescricao(_: any, p: any): void {
    setItensPrescricao([...itensPrescricao, { id: nextId(), tipo: "medicamento", posologia: "", quantidade: 1, ...p }]);

    setPesquisaMedicamento("");
    setState(State.incluso_prescricao);

    setPosologiaRef([...posologiaRef(), React.createRef()]);
    setQuantidadeFocusRef([...quantidadeFocusRef(), false]);
    iniciarIncluirPrescricao();
  }

  function getItensPrescricao(): any[] {
    return itensPrescricao;
  }

  function removerItemPrescricao(_: any, index: number): void {
    let nova = [...itensPrescricao];
    nova.splice(index, 1);
    setItensPrescricao(nova);;
  }


  function itemPrescricaoOnChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, p: any, prop: string): void {
    itemPrescricaoChange(p, prop, e.target.value)
  }

  function itemPrescricaoChange(p: any, prop: string, value: any): void {
    var item = { ...p };
    item[prop] = value;

    var nova = itensPrescricao.map((i) => i.id === p.id ? item : i);
    setItensPrescricao(nova);
  }

  function itemPrescricaoChangeObj(p: any, obj: any): void {
    var item = { ...p, ...obj };
    var nova = itensPrescricao.map((i) => i.id === p.id ? item : i);
    setItensPrescricao(nova);
  }


  function ajustarQuantidade(p: any, value: any): void {
    p.quantidade = value
    let ua = atualiarPluralUnidadeApresentacao(p);
    itemPrescricaoChangeObj(p, { quantidade: p.quantidade, unidadeApresentacao: ua });
  }

  function aumentarQuantidade(p: any): void {
    p.quantidade++;
    let ua = atualiarPluralUnidadeApresentacao(p);
    itemPrescricaoChangeObj(p, { quantidade: p.quantidade, unidadeApresentacao: ua });
  }

  function reduzirQuantidade(p: any): void {
    if (p.quantidade == 1)
      return;

    p.quantidade--;
    let ua = atualiarPluralUnidadeApresentacao(p);
    itemPrescricaoChangeObj(p, { quantidade: p.quantidade, unidadeApresentacao: ua });
  }

  function incluirTextoLivre() {
    setState(State.incluir_texto_livre);
    setItensPrescricao([...itensPrescricao, { id: nextId(), tipo: "texto_livre", composicao: pesquisaMedicamento(), posologia: "", quantidade: 1, tipoTexto: 1, unidadeApresentacao: "unidade" }]);

    setPesquisaMedicamento("");

    setPosologiaRef([...posologiaRef(), React.createRef()]);
    setQuantidadeFocusRef([...quantidadeFocusRef(), false]);

    return false;
  }


  return [getItensPrescricao, setItensPrescricao, incluirPrescricao, removerItemPrescricao, itemPrescricaoOnChange, itemPrescricaoChange, ajustarQuantidade, aumentarQuantidade, reduzirQuantidade, incluirTextoLivre];
}