const unidades = [
  ['unidade', 'unidades'],
  ['frasco', 'frascos'],
  ['comprimido', 'comprimidos'],
  ['caixa', 'caixas'],
  ['grama', 'gramas'],
  ['litro', 'litros'],
  ['bisnaga', 'bisnagas'],
  ['embalagem', 'embalagens']
];

const findSingular = (unidade: String) => {
  let u = unidades.filter(u => u[1] == unidade);
  return u.length > 0 ? u[0][0] : unidade;
}

const findPlural = (unidade: String) => {
  let u = unidades.filter(u => u[0] == unidade);
  return u.length > 0 ? u[0][1] : unidade;
}

export function atualiarPluralUnidadeApresentacao(p: any): String {
  let ua = p.unidadeApresentacao.toLowerCase();

  if (!p.quantidadeApresentacao) p.quantidadeApresentacao = 1;

  return p.quantidade * parseFloat(p.quantidadeApresentacao) == 1 ? findSingular(ua) : findPlural(ua);
}