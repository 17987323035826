import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { Accordion, Button, Col, ListGroup, Nav, Row } from 'react-bootstrap';
import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react';
import MenuCelular from './MenuCelular';
import PrescricaoPaciente from './PrescricaoPaciente';
import medicamentos from './Dados';
import { ChevronLeft, CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import MenuPrescricao from './MenuPrescricao';
import Pesquisa from '../componentes/Pesquisa';
import { useSendGet, useSendPost, useSendPut } from '../Server';
import { useNavigate, useParams } from 'react-router-dom';
import 'overlayscrollbars/overlayscrollbars.css';
import StringIndex from '../componentes/StringIndex';
import { useLocalCache, useLocalCacheUpdate } from '../componentes/LocalCache';
import State, { useStateHandler } from './prescricao/State';
import ResultadoMedicamento from './prescricao/ResultadoMedicamento';
import { vazio } from '../Validacao';
import { useAlerta } from '../componentes/Alerta';
import { verificaDataNascimento } from '../componentes/Validacao';
import { calcularIdade, descricaoIdade } from '../componentes/Idade';
import { updateTextAreaCustomHeight, useAtualizarPrescricoesRecentes, useIncId, useLoadPrescricao, usePesquisaMedicamentos, useSalvarPrescricao } from './prescricao/PrescricaoFuncoes';
import { atualiarPluralUnidadeApresentacao } from './prescricao/PrescricaoUnidades';
import { useItemPrescricao } from './prescricao/PrescricaoItens';
import { useItemPrescricaoRender, usePrescricaoRender } from './prescricao/PrescricaoRender';
import { ReactSortable } from "react-sortablejs";


function Prescricao() {

  const { id } = useParams();

  const navigate = useNavigate();

  const [prescricoesRecentes, setPrescricoesRecentes] = useState<any[]>([]);

  const [medicamentosList, _] = useState(StringIndex(medicamentos));

  const [pacientes, setPacientes] = useState([]);
  const [pesquisaMedicamento, setPesquisaMedicamento] = useState("");
  const [filteredMedicamento, setFilteredMedicamento] = useState<any[]>([]);


  const refHeight = useRef<any>();
  const refWidthPesquisa = useRef<any>();
  const pesquisaMedicamentoRef = useRef<any>();

  const [posologiaRef, setPosologiaRef] = useState<any[]>([]);

  const [quantidadeFocusRef, setQuantidadeFocusRef] = useState<any[]>([]);

  const [dados, setDados] = useState<any>({ paciente: {}, data: new Date().toISOString().split('T')[0] });

  const pacientesDados = useLocalCache("pacientes", "paciente/medico", setPacientes);
  const pacientesDadosUpdate = useLocalCacheUpdate("pacientes");

  const [MensagemAlerta, setAlerta] = useAlerta();

  const [stateData, setState, popState, getState, getLastState] = useStateHandler();

  const [setIdItem, nextId] = useIncId();

  const [getItensPrescricao, setItensPrescricao, incluirPrescricao, removerItemPrescricao, itemPrescricaoOnChange, itemPrescricaoChange, ajustarQuantidade, aumentarQuantidade, reduzirQuantidade, incluirTextoLivre] =
    useItemPrescricao(nextId, setPesquisaMedicamento, setState, () => posologiaRef, setPosologiaRef, () => quantidadeFocusRef, setQuantidadeFocusRef, ()=> pesquisaMedicamento, iniciarIncluirPrescricao);

  const atualizarFiltroMedicamentos = usePesquisaMedicamentos(setFilteredMedicamento, medicamentosList);

  useEffect(() => {
    atualizarFiltroMedicamentos(pesquisaMedicamento);
  }, [pesquisaMedicamento]);

  useEffect(() => {
    pacientesDados();
    iniciarIncluirPrescricao();
  }, []);

  useAtualizarPrescricoesRecentes(setPrescricoesRecentes);

  const loadPrescricao = useLoadPrescricao(setDados, setItensPrescricao, setIdItem, setPosologiaRef, setQuantidadeFocusRef);
  const salvarPrescricao = useSalvarPrescricao(setAlerta, () => dados, setDados, setState, getItensPrescricao, () => id, pacientesDadosUpdate);

  useEffect(() => { loadPrescricao(id); }, [id])

  useEffect(() => {
    if (getState() === State.incluindo_prescricao) {
      pesquisaMedicamentoRef.current?.focus();
    }
    else if (getState() === State.gerar_prescricao) {
      navigate("/prescricao-verificacao/" + dados.id, dados);
    }
    //eslint-disable-next-line
  }, [stateData]);



  function iniciarIncluirPrescricao() {
    setState(State.incluindo_prescricao);
  }

  useEffect(() => { posologiaRef[getItensPrescricao().length - 1]?.current?.focus(); }, [posologiaRef])


  function incluirDadosPaciente(): ReactNode {
    const state = getState();

    if (state !== State.incluir_alergia &&
      state !== State.incluir_condicao &&
      state !== State.incluir_paciente
    ) {
      return <></>
    }

    var def = (state === State.incluir_alergia ? "alergia" :
      state === State.incluir_condicao ? "condicao" :
        "info");
    return <PrescricaoPaciente setPaciente={(p: any) => { console.log(p); setDados({ ...dados, paciente: p }) }} paciente={dados.paciente} onHide={() => popState()} defaultView={def} />
  }

  function mostarFormularioPrescricao(): boolean | undefined {
    const state = getState();

    return (state !== State.incluir_alergia &&
      state !== State.incluir_condicao &&
      state !== State.incluir_paciente);
  }


  function mostrarPrecscricoesRecentes(): boolean {
    const state = getState();
    return state == State.INICIAL && prescricoesRecentes && prescricoesRecentes.length > 0;
  }

  function mostrarCampoPrescricao(): boolean {
    const state = getState();
    return state === State.incluindo_prescricao;
  }

  const [ultimasPrescricoes] = usePrescricaoRender(() => medicamentosList, mostrarPrecscricoesRecentes, () => prescricoesRecentes, navigate);

  const [itemPrescricaoRender, textoPrescricaoRender] = useItemPrescricaoRender(() => posologiaRef, itemPrescricaoOnChange, iniciarIncluirPrescricao,
    setQuantidadeFocusRef, () => quantidadeFocusRef, aumentarQuantidade, reduzirQuantidade, removerItemPrescricao, ajustarQuantidade);

  return (
    <div style={{ height: 600 + refHeight.current?.offsetHeight }} className='bg-secondary' >
      {incluirDadosPaciente()}
      <MenuPrescricao gerarPrescricaoFn={salvarPrescricao} />
      <Container fluid='sm' className='mh-100' ref={refHeight} >
        <Row className="mt-5">
          <Col xxl={{ span: 2 }}></Col>
          <Col ref={refWidthPesquisa} className="border  p-4 mh-100 bg-body" >
            <MensagemAlerta />
            <Row className="justify-content-end mb-2 ">
              <Col xs={{ span: 4 }} style={{ textAlign: 'right' }}>
                <Nav.Link href="/dashboard"><ChevronLeft /> Voltar</Nav.Link>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col md={{ span: 4 }}>
                <Stack gap={2} direction="horizontal">
                  <h6>Data</h6>
                  <Form.Control type="date" value={dados.data} onChange={(p: any) => setDados({ ...dados, data: p.target.value })} />
                </Stack>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Pesquisa selecao={dados.paciente} label="Nome ou CPF" placeholder='Buscar pelo nome ou CPF' itens={pacientes}
                  setSelecao={(p: any) => {
                    setDados({ ...dados, paciente: p })
                    console.log(p);
                  }}
                  filterFn={(p: any, pesq: any) => (p.nome.toLowerCase().indexOf(pesq.toLowerCase()) > -1 || p.cpf === pesq)}
                  notFoundContent={(p: any) => p ? <ListGroup.Item action onClick={() => {

                    setState(State.incluir_paciente);
                    setDados({ ...dados, paciente: { nome: p } });

                  }}>Criar '{p}'</ListGroup.Item> : ''} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={{ span: 2 }}>
                <span>Peso (kg)</span>
                <Form.Control type="input" value={dados.paciente?.peso} onChange={(p: any) => setDados({ ...dados, paciente: { ...dados.paciente, peso: p.target.value } })} />
              </Col>
              <Col sm={{ span: 3 }}>
                <h6>Data Nascimento</h6>
                <Form.Control type="date" value={dados.paciente?.dataNascimento}
                  onChange={(p: any) => setDados({ ...dados, paciente: { ...dados.paciente, dataNascimento: p.target.value } })}
                  onBlur={e => verificaDataNascimento(new Date(e.target.value), setAlerta)} />
              </Col>
              <Col sm={{ span: 3 }}>
                <h6>Idade</h6>
                <p>{dados.paciente?.dataNascimento ? descricaoIdade(calcularIdade(new Date(dados.paciente.dataNascimento))) : null}</p>
              </Col>
              <Col sm={{ span: 4 }} className='float-right'>
                <Button variant="link" onClick={() => setState(State.incluir_paciente)}>Outras Informaçoes...</Button>
                {dados.paciente?.sexo != 1 ? 
                <></>: 
                  <Stack direction="horizontal" gap={2}>
                    <Form.Check type="checkbox" label="Gravidez"
                      checked={dados.paciente.lactante} onChange={(e) => setDados({...dados, paciente: { ...dados.paciente, lactante: e.target.checked }})} />
                    <Form.Check type="checkbox" label="Lactação"
                      checked={dados.paciente.gestante} onChange={(e) => setDados({...dados, paciente: { ...dados.paciente, gestante: e.target.checked }})} />
                  </Stack>}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Stack direction="horizontal" gap={2} className='float-right'>
                  {dados.paciente?.alergias?.length ? <Button variant="warning" onClick={() => setState(State.incluir_alergia)}>
                    {"Alergia a " + dados.paciente.alergias.map((a: any) => a.nome).join(', ')}</Button> : ''}
                </Stack>
              </Col>
            </Row>

            <Row key="itensPrescricao" className="border-top mt-3" lista-item-container>
              <ReactSortable list={getItensPrescricao()} setList={setItensPrescricao} handle='.item-prescricao-handle'> 
                {getItensPrescricao().map(
                  (p: any, index: any) => p.tipo === "medicamento" ?
                    itemPrescricaoRender(p, index) :
                    textoPrescricaoRender(p, index)
                )}
              </ReactSortable>
            </Row>

            <Row style={{ display: mostrarCampoPrescricao() ? "block" : "none" }} className="mt-3">
              <Col>
                <Stack gap={2} direction="horizontal">
                  <Form.Control ref={pesquisaMedicamentoRef} type="input" value={pesquisaMedicamento} onChange={e => setPesquisaMedicamento(e.target.value)}
                    placeholder='Digite aqui para buscar itens e adicioná-los à prescrição' onKeyDown={e => {
                      if (e.key.toLowerCase() === 'enter') { incluirTextoLivre(); e.preventDefault(); e.stopPropagation(); }
                    }}
                    className='custom-select placeholder-blue border-0' list='pesquisa-medicamento' />
                </Stack>

                <Col sm={{ span: 12 }} md={{ span: 4 }} className="position-absolute"
                  style={{ marginLeft: 20, marginTop: -1, zIndex: 1000, minWidth: 700 }}>
                  <ResultadoMedicamento filtered={filteredMedicamento} searchTerm={pesquisaMedicamento} incluirTextoLivre={incluirTextoLivre}
                    selecaoMedicamento={incluirPrescricao} widthPesquisa={() => refWidthPesquisa.current?.offsetWidth} />
                </Col>
              </Col>
            </Row>
            <Stack gap={2} className="mt-3 col-md-8 mx-auto">
              <Button variant="primary" onClick={() => salvarPrescricao()}>Gerar prescrição</Button>
            </Stack>
            {ultimasPrescricoes()}
          </Col>
          <Col xxl={{ span: 2 }}></Col>
        </Row>
      </Container>
      <MenuCelular />
    </div>
  );

}

export default Prescricao;
