import { useState } from "react";

enum State {
  INICIAL,
  paciente_selecionado,
  incluindo_prescricao,
  incluso_prescricao,
  incluir_paciente,
  incluir_alergia,
  incluir_condicao,
  gerar_prescricao,
  incluir_texto_livre
}

export default State;

export const useStateHandler = ():
  [any[], (s:State) => void, () => void, () => State, () => State] => {
   
    const [stateData, setStateValue] = useState([State.INICIAL, State.INICIAL]);

    const state = stateData[0];
    const lastState = stateData[1];
  

    function setState(s: State) {
      setStateValue([s, ...stateData]);
    }

    function popState():void {
      setStateValue(stateData.slice(1));
    }
    
    function getState():State {
      return state;
    }
    
    function getLastState() :State{
      return lastState;
    }
    
    function isStateChange(from: State, to: State) {
      return lastState === from && state === to;
    }
    
    return [stateData, setState, popState, getState, getLastState];
}