import Form from 'react-bootstrap/Form';
import { Button,   Col, Container,  Modal, Row, Spinner, Stack } from 'react-bootstrap';
import MenuPrincipal from './MenuPrincipal';
import { useEffect, useState } from 'react';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import {  useSendGet, useSendGetFile } from '../Server';
import Config from './Config';
import DocumentViewer from '../componentes/DocumentViewer';
import printJS from 'print-js';

function PrescricaoVisualizacao() {
  const { id } = useParams();

  let { state } = useLocation();
  if (state == null) {
    state = {dados: {}, opcoes: {}}
  }
  
  console.log(state);

  const navigate = useNavigate();
  const sendGetFile = useSendGetFile();
  const sendGet = useSendGet();

  const [url, setUrl] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  
  const [visualizar, setVisualizar] = useState<any>(false);
  
  const [data, setData] = useState<any>(state);
  
  let request = false;

  useEffect(() => {
    if(request) return;
    request = true;

    if(data.dados.prescricao == null){
      sendGet("prescricao/verificacao/" + id, (response: any) => {
        setData({dados: response.data});
        setVisualizar(true);
      }, 
      (error:any) => {
        console.log(error);
      })
    }
    
    sendGetFile("prescricao/download_prescricao/" + id, (response: any) => {
      request = false;
      setUrl(response.data);
      if(state.opcoes.imprimirPrescricao){
        setTimeout(() =>{
          imprimirPrescricao(response.data); 
        }, 100)
      }

      if(data.dados.token != null){
        setVisualizar(true);
      }
    });
  }, []);
  

  function downloadPrescricao() {
    setMessage("Salvando prescricao...");
    const link = document.createElement('a');
    link.href = URL.createObjectURL(url);
    link.setAttribute('download', 'prescricao' + id + '.pdf');
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
    setMessage(null);
  }

  let imprimirPrescricaoCall = false;
  
  function imprimirPrescricao(turl:any): void {

      if(imprimirPrescricaoCall) return;
      imprimirPrescricaoCall = true;
      
      const ourl = URL.createObjectURL(turl);

      printJS({printable: ourl, type:'pdf', showModal:true, onPrintDialogClose: ()=> {
        console.log("Imprimiu");
        setMessage(null);
        setVisualizar(true);
        imprimirPrescricaoCall = false;
      }});
      URL.revokeObjectURL(ourl);
  };

  return <>
    <MenuPrincipal apenasLogo={true} logoLink="/" />
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>Prescricão</h2>
        </Col>
        <p>O arquivo da prescrição pode ser visualizado abaixo</p>
      </Row>
      <Row className="mt-3">
        <Col>
         {visualizar ?
          <DocumentViewer url={Config.get().url("prescricao/visualizacao/" + id + "/" + data.dados.token)} />:
          null
         } 
        </Col>
      </Row>
      <Form>
        <Row className="mt-3">
          <Col className="float-right">
            <Stack direction="horizontal" gap={3}>
              <Button className='ms-auto' type='button' variant='primary' onClick={() => downloadPrescricao()}>Salvar uma cópia</Button>
              <Button type='button' variant='primary' onClick={() => imprimirPrescricao(url)}>Imprimir</Button>
              <Button type='button' variant='primary' onClick={() => navigate("/prescricao/"+id)}>Editar</Button>
              <Button type='button' variant='primary' onClick={() => navigate("/prescricao")}>Fechar</Button>
            </Stack>
          </Col>
        </Row>
      </Form>
    </Container>
    <Modal show={message != null}  aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Body>
          <Stack direction="horizontal" gap={3}>
            <Spinner className="float-left" animation="border" role="status" variant="primary" >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>{message}</div>
          </Stack>
      </Modal.Body>
    </Modal>
  </>
}
export default PrescricaoVisualizacao;
